import { createStyles, Accordion, Container, Title, Flex, Text } from "@mantine/core"
import { Image } from "../../shared/ui"
import React from "react"

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: `calc(${theme.spacing.xl} * 3)`,
    marginBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      marginTop: `calc(${theme.spacing.xl} * 2)`,
      marginBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      marginTop: `calc(${theme.spacing.xl} * 1.5)`,
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    marginBottom: 48,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 32,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },

    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
    },
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[7],
  },

  mainFlex: {
    gap: 48,

    [theme.fn.smallerThan(768)]: {
      flexDirection: "column",
    },
  },

  leftFlex: {
    width: "50%",
    alignSelf: "flex-start",

    [theme.fn.smallerThan(768)]: {
      width: "100%",
    },
  },

  rightFlex: {
    width: "50%",

    [theme.fn.smallerThan(768)]: {
      width: "100%",
    },
  },
}))

export function Faq() {
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <Container size={1320}>
        <Flex className={classes.mainFlex}>
          <Flex className={classes.leftFlex} align="center" justify="center">
            <Image objectFit="contain" src="assets/undraw_questions_re_1fy7.svg" alt="Часто задаваемые вопросы"/>
          </Flex>

          <Flex className={classes.rightFlex} direction="column">
            <Title order={2} className={classes.title}>
              <Text span variant="gradient"
                    gradient={{ from: "orange.5", to: "red.7", deg: 105 }}>Часто</Text> задаваемые вопросы
            </Title>

            <Accordion radius="xl" chevronPosition="right" defaultValue="1" variant="separated">
              <Accordion.Item className={classes.item} value="1">
                <Accordion.Control>Как записаться в ближайший поток и начать обучение?</Accordion.Control>
                <Accordion.Panel>Нажмите на кнопку "оплатить обучение" . После этого вам на почту придут данные для
                  входа в личный кабинет на нашей образовательной платформе. Координаторы учебного центра свяжутся с
                  вами в течение 10 минут, чтобы сориентировать вас по всем вопросам и закрепить за вами
                  куратора.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="2">
                <Accordion.Control>Когда старт нового потока и сколько длится обучение?</Accordion.Control>
                <Accordion.Panel>Ближайший поток стартует 15 марта 2025 года. Обучение длится 6
                  месяцев.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="3">
                <Accordion.Control>Какие документы я получу после прохождения обучения?</Accordion.Control>
                <Accordion.Panel>После прохождения курса и сдачи итогового экзамена - вы получаете профессиональное
                  удостоверение с присвоением квалификации "Нутрициолог", перечень пройдённых предметов и сертификат.
                  Данные документы дают вам право вести профессиональную деятельность.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="4">
                <Accordion.Control>
                  Подойдет для учебная программа "новичку"?
                </Accordion.Control>
                <Accordion.Panel>Образовательный курс устроен таким образом, что подойдет как специалисту, который уже
                  имеет определенный уровень знаний в сфере нутрициологии так и тем, кто только начинает свой путь в
                  сфере питания. С каждым студентом работает личный куратор, который помогает усвоить учебный материал и
                  отвечает на все возникающие вопросы в процессе обучения.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="5">
                <Accordion.Control>Есть ли рассрочка? Какие условия?</Accordion.Control>
                <Accordion.Panel>В нашем образовательном центре есть возможность приобрести обучение в рассрочку. Без %
                  и переплат на срок до 6 месяцев. Вы выбираете комфортную для вас сумму платежа и приступаете к
                  обучению.</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="6">
                <Accordion.Control>Можно ли вернуть деньги, если мне не подошло обучение?</Accordion.Control>
                <Accordion.Panel>Да, вы можете вернуть денежные средства, если вам не подошло обучение в течение 14 дней
                  с начала старта учебного потока. </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Flex>
        </Flex>
      </Container>
    </div>
  )
}
