import {
  Overlay,
  ActionIcon,
  Container,
  createStyles,
  Title,
  Flex,
  Box,
  List,
  Text,
  Button, Center,
} from "@mantine/core"
import React from "react"
import { IconPlayerPlayFilled, IconPoint } from "@tabler/icons-react"
import { Image } from "../../shared/ui"
import { useMediaQuery } from "@mantine/hooks"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 3)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    background: theme.black,
    backgroundImage: "repeating-linear-gradient(150deg,rgba(255,255,255,.03),rgba(255,255,255,.03) 1px,transparent 0,transparent 41px),repeating-linear-gradient(-150deg,rgba(255,255,255,.03),rgba(255,255,255,.03) 1px,transparent 0,transparent 41px)",
    backgroundSize: "82px 47px",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
  },

  title: {
    marginBottom: 64,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      marginBottom: 48,
      fontSize: 32,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
      marginBottom: 24,
    },
  },

  box: {
    overflow: "hidden",
    position: "relative",
    borderRadius: 16,

    [theme.fn.smallerThan(768)]: {
      maxWidth: 640,
    },
  },

  info: {
    color: theme.white,

    [theme.fn.smallerThan(768)]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },

  list: {
    marginTop: 48,

    "li": {
      color: theme.white,
    },

    [theme.fn.smallerThan("xs")]: {
      marginTop: 24,
    },

    [theme.fn.smallerThan(768)]: {
      maxWidth: 640,
    },
  },

  flex: {
    gap: 48,
    alignItems: "flex-start",

    [theme.fn.smallerThan("md")]: {
      gap: 24,
    },

    [theme.fn.smallerThan(768)]: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },

  subTitle: {
    fontWeight: 700,
    fontSize: 24,

    [theme.fn.smallerThan("md")]: {
      fontWeight: 500,
      fontSize: 20,
    },

    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 18,
    },
  },
}))

export function TestDrive(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const is425 = useMediaQuery(`(max-width: 425px)`)

  return (
    <div id="test-drive" className={classes.wrapper}>
      <Container size={1320}>
        <Title className={classes.title} color="white" order={2} align="center">
          Устройте курсу&nbsp;{is425 && <br/>}тест-драйв
        </Title>

        <Flex className={classes.flex}>
          <Box className={classes.box}>
            <Image
              objectFit="contain"
              src="assets/test-drive.png"
            />
            <Overlay opacity={0.3} blur={0} center>
              <ActionIcon
                gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
                size={56}
                radius={28}
                variant="gradient"
                component="a"
                href="https://academy.bodycoach.pro/buy/227"
                target="_blank"
              >
                <IconPlayerPlayFilled size={20}/>
              </ActionIcon>
            </Overlay>
          </Box>

          <Flex className={classes.info} direction="column">

            <Text className={classes.subTitle}>
              Пройдите вводный модуль и убедитесь, что обучение вам точно подходит!
            </Text>

            <List
              className={classes.list}
              spacing="md"
              size={md ? "md" : "lg"}
              icon={<IconPoint size={24} style={{ color: "#6464d9" }}/>}
            >
              <List.Item>Доказательный нутрициолог. Тренд 2025 года</List.Item>
              <List.Item>Требования к нутрициологу. Какими навыками и знаниями должен обладать специалист по
                питанию</List.Item>
              <List.Item>Сколько может зарабатывать нутрициолог? Актуальные инструменты монетизации</List.Item>
            </List>
          </Flex>
        </Flex>

        <Center mt={is425 ? 24 : 48}>
          <Button
            radius="xl"
            size={md ? "md" : "lg"}
            variant="gradient"
            gradient={{ from: "teal.7", to: "green.7", deg: 105 }}
            component="a"
            href="https://academy.bodycoach.pro/buy/227"
            target="_blank"
          >
            Начать тест-драйв!
          </Button>
        </Center>

      </Container>
    </div>

  )
}
